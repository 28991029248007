import DateHelper from '@launchpad/util/DateHelper';
import React from 'react';
import CardList from '../modules/card/CardList';
import KabnLatestUsers from '../modules/dashboard/KabnLatestUsers';
import UpdateUserCardInfoButton from '../modules/users/all-users/pages/components/UpdateUserCardInfoButton';

const overrides = {
  login: {
    page: {
      logo: () => (
        <img
          className="logo"
          src={require('../../assets/img/KABN-LOGO.png')}
          alt="kabn-logo"
        />
      ),
      text: () => (
        <h1>
          {' '}
          Welcome to KABN{' '}
          <span style={{ display: 'block', textAlign: 'center' }}>
            Administration
          </span>
        </h1>
      )
    }
  },
  sidemenu: {
    logo: () => (
      <div className="dash-logo">
        <img src={require('../../assets/img/KABN-LOGO.png')} alt="logo" />
      </div>
    )
  },
  pages: {
    home: {
      widgets: { bottom: () => <KabnLatestUsers />, 'last-bottom': () => null }
    }
  },
  user: {
    details: {
      basic: (props: { user: any }) => {
        let user = props.user;
        return (
          <div className="row text-white">
            <div style={{ marginBottom: 20 }} className="col-lg-6">
              <h4>Basic Info</h4>
              <hr />
              <dl>
                <dt>First name:</dt>
                <dd>{user.firstName}</dd>
                <dt>Last name:</dt>
                <dd>{user.lastName}</dd>
                <dt>E-mail address:</dt>
                <dd>{user.email}</dd>
                <dt>Customer reference:</dt>
                <dd>{user.customerReference}</dd>
                <dt>Birth date:</dt>
                <dd>{DateHelper.date(user.birthDate, 'D / MMMM / Y')}</dd>
              </dl>
            </div>
            <div className="col-lg-6">
              <h4>Address Info</h4>
              <hr />
              <dl>
                <dt>Address:</dt>
                <dd>{user.addressOne || '-'}</dd>
                <dt>Apartment/Suite:</dt>
                <dd>{user.addressTwo || '-'}</dd>
                <dt>City:</dt>
                <dd>{user.city || '-'}</dd>
                <dt>Province:</dt>
                <dd>{user.state || '-'}</dd>
                <dt>Country:</dt>
                <dd>{user.countryName || '-'}</dd>
                <dt>Postal Code:</dt>
                <dd>{user.postCode || '-'}</dd>
                <dt>Phone Number:</dt>
                <dd>{user.mobile || '-'}</dd>
              </dl>
            </div>
          </div>
        );
      }
    }
  },
  card: {
    'change-status-btn': () => null,
    'lost-stolen-btn': () => null,
    expires: () => null,
    '3ds-btn': () => null,
    'card-list': () => <CardList />,
    'update-card-info': (props: any) => (
      <UpdateUserCardInfoButton id={props.card.id} />
    ),
    preview: (props: {
      card: {
        status: string;
        pan: { toString: () => string };
        // expires:
        //   | boolean
        //   | React.ReactChild
        //   | React.ReactFragment
        //   | React.ReactPortal
        //   | null
        //   | undefined;
        emboss:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined;
      };
    }) => (
      <div style={{ position: 'relative', maxWidth: 500 }}>
        <img
          src={require('../../assets/img/card-front-blue.png')}
          style={{ width: '100%', height: 'auto' }}
          alt="card"
        />
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%'
          }}
        >
          {!props.card.status && (
            <>
              {props.card.status === 'active' || 'stolen' || 'locked' ? (
                <span
                  style={{
                    position: 'absolute',
                    left: '12%',
                    bottom: '33%',
                    fontSize: 20
                  }}
                >
                  {props.card.pan &&
                    props.card.pan
                      .toString()
                      .replace(/(.{4})/g, '$1 ')
                      .trim()}
                </span>
              ) : null}
              {/*props.card.status === 'active' || 'stolen' || 'locked' ? (
                <span
                  style={{ position: 'absolute', left: '43%', bottom: '19%' }}
                >
                  {props.card.expires}
                </span>
              ) : null */}
              {props.card.status === 'active' || 'stolen' || 'locked' ? (
                <span
                  style={{
                    position: 'absolute',
                    left: '12%',
                    bottom: '6%',
                    fontSize: 20
                  }}
                >
                  {props.card.emboss}
                </span>
              ) : null}
            </>
          )}
        </div>
      </div>
    )
  },
  panLabel: 'Proxy Number'
};

export default overrides;
