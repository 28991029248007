import BaseModel from '@launchpad/logic/model/BaseModel';
import Api from '@launchpad/logic/api/Api';

export default class UserPaymentCardModel extends BaseModel {
  /**
   * Get list url
   */
  getListUrl() {
    return 'payment-cards/list';
  }

  /**
   * Get update user card info url
   */
  updateUserCardInfoUrl() {
    return 'payment-device/balance/update';
  }

  async updateUserCardInfo(id) {
    let result = await Api.call(this.updateUserCardInfoUrl(), {
      id
    });
    return result;
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field'
      },
      {
        name: 'pan',
        label: 'Proxy Number',
        sortable: false,
        type: 'field'
      },
      {
        name: 'name',
        label: 'Name',
        sortable: false,
        type: 'field'
      },
      {
        name: 'paymentMethod',
        label: 'Type',
        sortable: false,
        type: 'field'
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions'
      }
    ];
  }
}
