import React from 'react';
import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';

export const NAME = 'users';

export default class UsersModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'user.*';
  }

  getNavigation() {
    return [
      {
        label: 'Users',
        icon: 'users',
        path: null,
        permission: 'user.*',
        children: [
          {
            label: 'All Users',
            path: '/users/all-users',
            icon: 'users',
            permission: 'user.view'
          },
          {
            label: 'Loaded Users',
            path: '/users/loaded-users',
            icon: 'upload',
            permission: 'user.upload'
          },
          {
            label: 'Add New Users',
            path: '/users/add-new-users',
            icon: 'user-plus',
            permission: 'user.add'
          }
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}users`}
        load={() => import('./UsersPage')}
      />
    ];
  }
}
