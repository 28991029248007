import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CardModel from './models/CardModel';
import { AppTable } from '@launchpad';
import BreadcrumbHelper from '@launchpad/util/BreadcrumbHelper';
import AppBadge from '@launchpad/components/elements/icon/AppBadge';
import NumberHelper from '@launchpad/util/NumberHelper';

export default class CardList extends Component {
  constructor(props) {
    super(props);
    BreadcrumbHelper.setCrumbs([
      {
        label: 'Cards'
      }
    ]);
  }
  _renderStatus(item) {
    switch (item.status) {
      case 'active':
      case 'ok':
        return <AppBadge text="OK" icon="check" />;
      case 'locked':
        return <AppBadge text="Locked" icon="lock" type="danger" />;
      case 'stolen':
        return <AppBadge text="Stolen" icon="ban" type="danger" />;
      case 'pending_activation':
        return (
          <AppBadge text="Pending activation" icon="clock-o" type="info" />
        );
    }
  }

  /**
   * Render
   *
   * @returns {XML}
   */
  render() {
    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-credit-card-alt" aria-hidden="true"></i> Cards
        </h1>
        <AppTable
          title="Cards"
          filter={[
            {
              name: 'term',
              type: 'term'
            }
          ]}
          renderCell={(column, item, index) => {
            switch (column.name) {
              case 'status':
                return this._renderStatus(item);
            }
            if (column.name === 'userFullName') {
              return (
                <Link
                  className="userCardLink"
                  to={`/users/all-users/details/${item.userId}`}
                >
                  {item.userFullName}
                </Link>
              );
            }
            if (column.name === 'balance') {
              return NumberHelper.currency(item.balance, item.currency);
            }
          }}
          model={CardModel}
          getColumnActions={(member, index) => {
            return (
              <div>
                <Link
                  to={`/cards/details/${member.id}`}
                  className="button-info"
                >
                  Details
                </Link>
              </div>
            );
          }}
        />
      </div>
    );
  }
}

// Inject router
CardList.contextTypes = {
  router: PropTypes.object
};
