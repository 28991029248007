import React from 'react';
import { LaunchPadAdmin } from '../@launchpad';
import configuration from './configuration';

require('./configuration/styles/overrides.scss');

function KabnAdmin() {
  return <LaunchPadAdmin configuration={configuration} />;
}

export default KabnAdmin;
