import BaseModel from '@launchpad/logic/model/BaseModel';
import Api from '@launchpad/logic/api/Api';

export default class CardModel extends BaseModel {
  /**
   * Get list url
   */
  getListUrl() {
    return 'cards/list';
  }

  /**
   * Get details url
   */
  getDetailsUrl() {
    return 'cards/details';
  }
  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'cards/order';
  }

  /**
   * Get download url
   */
  getDownloadUrl() {
    return 'cards/list/download';
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: true,
        type: 'field'
      },
      {
        name: 'pan',
        label: 'Proxy Number',
        sortable: false,
        type: 'field'
      },
      {
        name: 'userFullName',
        label: 'User',
        sortable: false,
        type: 'field'
      },
      {
        name: 'balance',
        label: 'Balance',
        sortable: false,
        type: 'field'
      },
      {
        name: 'status',
        label: 'Status',
        sortable: false,
        type: 'field'
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions'
      }
    ];
  }
  /**
   * Get transaction of the card
   *
   * @param {Integer} id Id of the card
   */
  async getTransaction(id) {
    alert(id);
    let result = await Api.call('transaction/list', {
      id
    });

    return result.data;
  }

  /**
   * Change freeze status
   *
   * @param {Object} card Card to change freeze status
   * @param {Bool} status Frozen or not
   */
  async changeFreezeStatus(card, status) {
    let response = await this.freezeCard(card.id, status);

    if (response.success) {
      return true;
    }

    return false;
  }

  async freezeCard(id, status) {
    let response = await Api.call('card/lock-unlock', {
      id: id,
      locked: !!status
    });

    return response;
  }

  /**
   * Change stolen status
   *
   * @param {Object} card Card to change stolen status
   * @param {Bool} status Stolen or not
   */
  async changeStolenStatus(card, status) {
    let response = await this.stolenCard(card.id, status);

    if (response.success) {
      return true;
    }

    return false;
  }

  async regenerateCard(card, status) {
    let response = await Api.call('card/regenerate', {
      id: card.id,
      status
    });

    if (response.success) {
      return response.data;
    }

    return response.data;
  }

  /**
   * Marks card as stolen
   *
   * @param {integer} id Id of the card
   */
  async stolenCard(id) {
    let response = await Api.call('card/report-stolen', {
      id
    });

    return response;
  }

  async update3DS(id) {
    let response = await Api.call('payment-device/update-3ds', { id });
    return response.success;
  }

  async remove3DS(id) {
    let response = await Api.call('payment-device/remove-3ds', { id });
    return response.success;
  }

  async changeCardStatus(id, status) {
    let response = await Api.call('card/change-status', {
      id,
      status
    });

    return response.success;
  }
}
