import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AppTable } from '@launchpad';
import UserModel from '../users/all-users/models/UserModel';
import DateHelper from '@launchpad/util/DateHelper';

export default class KabnLatestUsers extends Component<any, any> {
  _tableComponent: any;

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="activated-cards">
            {/* <h1><i className="fa fa-bell" aria-hidden="true"></i> Add New Push Notifications</h1> */}
            <div className="dark-blur"></div>
            <div className="box-header">
              <h2>
                <i className="fa fa-users"></i> Latest Users
              </h2>
              <div className="box-header-action">
                <Link to="/users/all-users" className="button">
                  List all
                </Link>
              </div>
            </div>
            <div className="box-body">
              <AppTable
                title={null}
                onInit={(tableComponent: any) =>
                  (this._tableComponent = tableComponent)
                }
                filter={null}
                paginate={false}
                params={{
                  limit: 10
                }}
                parseUrl={false}
                order={[
                  {
                    field: 'id',
                    direction: 'desc'
                  }
                ]}
                model={UserModel}
                renderCell={(
                  column: { name: any },
                  item: { birthDate: any },
                  index: any
                ) => {
                  switch (column.name) {
                    case 'birthDate':
                      return DateHelper.date(item.birthDate);
                  }
                }}
                getColumnActions={(member: { id: any }, index: any) => {
                  return (
                    <div>
                      <Link
                        to={`/users/all-users/details/${member.id}`}
                        className="button-info"
                      >
                        Details
                      </Link>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
