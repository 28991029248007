export const getNavigation = (appNavigation: any) => {
  const navigation = appNavigation.filter(
    x =>
      // x.slug !== 'push-notifications' &&
      x.slug !== 'kyc'
    // x.label !== 'FX Rates' &&
    // x.label !== 'Analytics' &&
    // x.label !== 'Content Management' &&
    // x.label !== 'Notifications' &&
    // x.label !== 'Reports' &&
    // x.label !== 'Rewards' &&
    // x.label !== 'Tools' &&
    // x.label !== 'Shop' &&
    // x.label !== 'Chat' &&
    // x.label !== 'Dictionary'
  );

  const ContentManagement = navigation.find(
    x => x.label === 'Content Management'
  );

  const children = ContentManagement.children
    .filter(x => x.label !== 'Config')
    .filter(x => x.label !== 'Countries')
    .filter(x => x.label !== 'Currencies')
    .filter(x => x.label !== 'Transaction Merchant')
    .filter(x => x.label !== 'Merchant Category Code')
    .filter(x => x.label !== 'Transaction Categories');

  ContentManagement.children = children;

  // const tools = navigation.find(x => x.slug === 'tools');

  // tools.children = tools.children.filter(x => x.label !== 'Bulk Load');
  // tools.children.push({
  //   label: 'Load Report',
  //   icon: 'file',
  //   path: '/tools/load-report',
  //   permission: 'tools.loadReport.*'
  // });

  return navigation;
};

export default {
  getNavigation
};
