import React, { Component } from 'react';
import UserPaymentCardModel from '../../models/UserPaymentCardModel';
import CircularProgress from '@material-ui/core/CircularProgress';
import ToastHelper from '@launchpad/util/ToastHelper';

export default class UpdateUserCardInfoButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  async _updateUserCardInfo(id) {
    this.setState({
      isLoading: true
    });
    try {
      let result = await new UserPaymentCardModel().updateUserCardInfo(id);

      if (result.success) {
        this.setState(
          {
            isLoading: false
          },
          () => {
            window.location.reload();
          }
        );
      }
    } catch (e) {
      console.log(e);
      this.setState(
        {
          isLoading: false
        },
        () => {
          ToastHelper.show(
            e.message
              ? e.message
              : 'Oops, something is wrong, you can not finish this action.'
          );
        }
      );
    }
  }

  render() {
    let buttonText = 'Update';

    if (this.state.isLoading) {
      buttonText = (
        <CircularProgress
          className="circle-progress"
          color="inherit"
          size={18}
        />
      );
    }

    return (
      <button
        onClick={() => this._updateUserCardInfo(this.props.id)}
        className="button-info btn-block"
      >
        {buttonText}
      </button>
    );
  }
}
