import CardsModule from '@launchpad/modules/cards/CardsModule';
import ContentManagementModule from '@launchpad/modules/content-managament/ContentManagementModule';
import DashboardModule from '@launchpad/modules/dashboard/DashboardModule';
import LegalModule from '@launchpad/modules/legal/LegalModule';
import MyAccountModule from '@launchpad/modules/my-account/MyAccountModule';
import TransactionsModule from '@launchpad/modules/transactions/TransactionsModule';
import UsersModule from './users/UsersModule';

const modules: any = [
  new DashboardModule(),
  new UsersModule(),
  new CardsModule(),
  new ContentManagementModule(),
  new TransactionsModule(),
  new LegalModule(),
  new MyAccountModule()
];

export default modules;
